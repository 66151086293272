import React from 'react'
import TextLink from 'components/text-link'
import Layout from 'layouts/layout'
import Header from 'components/header'

export default () => (
  <Layout>
    <main>
      <Header layout="standard" />
      <section className="padding-md">
        <h1 className="h1 lg:u-2/3 mb-40">
          Page not found
        </h1>

        <TextLink color="terracotta" url="/">
          <span className="u-bold">Return to the homepage</span>
        </TextLink>

      </section>

    </main>
  </Layout>
)